import React, { useState } from "react";
import { formatDate } from "../../utilities/util";
import { FiLink, FiCheck, FiMail } from "react-icons/fi";
import { Button } from "react-bootstrap";

export const PastEstimatesList = ({ element, path, index }) => {
    const s3Url = "https://todd-thomas-painting.s3.us-west-2.amazonaws.com/";
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000); // Reset to link icon after 1 second
    };

    const sendEmail = (pdfUrl) => {
        const emailSubject = "Todd Thomas Painting Estimate";
        const emailBody = `Please find your estimate at the following link: \n\n ${pdfUrl}`;
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
        window.location.href = mailtoUrl;
    };

    return (
        <p key={`${path}-${index}`}>
            <a
                href={s3Url + element.Key}
                target="_blank"
                rel="noopener noreferrer"
            >
                {element.Key.toString().replace(path + "/", "Estimate Link")}
            </a>
            {element.Key === path + "/" ? null : (
                <span style={{ marginLeft: "10px" }}>
                    Created: {formatDate(element.LastModified)}
                </span>
            )}
            <Button
                variant="primary"
                onClick={() => copyToClipboard(s3Url + element.Key)}
                disabled={copied}
                style={{ marginLeft: "10px" }}
            >
                {copied ? <FiCheck /> : <FiLink />}
            </Button>
            <Button
                variant="primary"
                onClick={() => sendEmail(s3Url + element.Key)}
                style={{ marginLeft: "10px" }}
            >
                <FiMail />
            </Button>
        </p>
    );
};
