import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import Layout from "../Layout/Layout";
import { LineItems } from "../LineItems/LineItems";
import styled from "styled-components";
import { getHomeSqft, postEsimtate, postEstimateToDB } from "../../utilities/api";
import { getDate } from "../../utilities/util";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Spinner, Alert } from 'react-bootstrap';

const Styles = styled.div`
  margin-top: 2%;
  margin-left: 3%;
  .listingsFilter {
    width: 30%;
    max-width: 425px;
    display: inline-block;
    float: left;
    background-color: #d3d3d3;
    padding: 15px;
  }

  .listingsData {
    width: 70%;
    max-width: 200px;
    float: left;
    display: inline-block;
    padding: 15px;
    margin-left: 30px;
  }
`;
//PRICING CONSTS
const deluxeRate = 1.2;
const ultimateRate = 1.25;
const maximumRate = 1.3;

export const ExteriorEstimate = () => {

  //STATE VARIABLES
  const [exteriorState, setExteriorState] = useState({
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    note: "",
    noteTemp: "",
    responseData: {},
  });

  const [pricing, setPricing] = useState({
    deluxePackagePrice: 0,
    ultimatePackagePrice: 0,
    maximumPackagePrice: 0,
  });

  const [address, setAddress] = useState("");
  const [homeSqft, setHomeSqft] = useState(0);
  const [sqftFetchState, setSqftFetchState] = useState("");

  const [lineItems, setLineItems] = useState([{ description: "", cost: "" }]);

  const handleAddressChange = (address) => {
    setSqftFetchState("loading");
    setAddress(address);
    setExteriorState((prevState) => ({
      ...prevState,
      clientAddress: address.label,
    }));

    getHomeSqft(address.label).then((data) => {
      if (data) {
        let value = data.sqft;
        setHomeSqft(value);
        setPricing({
          deluxePackagePrice:
            "$" +
            Math.round(value * deluxeRate)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ultimatePackagePrice:
            "$" +
            Math.round(value * ultimateRate)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          maximumPackagePrice:
            "$" +
            Math.round(value * maximumRate)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        }
        );
        setSqftFetchState("success");
      }
      else {
        setHomeSqft(0);
        setSqftFetchState("failure");
      }
    }
    );
  };

  //METHODS
  const handleSqftChange = (e) => {
    let value = e.target.value;
    setSqftFetchState("");
    setHomeSqft(value);
    //Set Sqft
    setPricing({
      deluxePackagePrice:
        "$" +
        Math.round(value * deluxeRate)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ultimatePackagePrice:
        "$" +
        Math.round(value * ultimateRate)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      maximumPackagePrice:
        "$" +
        Math.round(value * maximumRate)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    }
    );
  };

  const handleStateChange = (e) => {
    if (e.target.name === "homeSqft") {
      handleSqftChange(e);
    } else {
      const { name, value } = e.target;
      setExteriorState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  function createEstimate() {
    let date = getDate();
    let outputFileName = exteriorState.clientName.replace(" ", "") + "-" + date;
    let fields = {
      clientNamePdf: exteriorState.clientName,
      clientAddressPdf: address.label,
      clientEmailPdf: exteriorState.clientEmail,
      clientPhonePdf: exteriorState.clientPhone,
      deluxePackagePricePdf: pricing.deluxePackagePrice,
      ultimatePackagePricePdf: pricing.ultimatePackagePrice,
      maximumPackagePricePdf: pricing.maximumPackagePrice,
      notes: exteriorState.note,
    };

    postEstimateToDB(
      exteriorState.clientName,
      exteriorState.clientPhone,
      exteriorState.clientEmail,
      address.label,
      "EXTERIOR"
    ).then(response => {
      console.log(JSON.stringify(response));
    });

    postEsimtate(
      "ExteriorTemplateForm.pdf",
      "ExteriorEstimates",
      outputFileName,
      fields
    ).then((response) => {
      if (response.status !== 200) {
        //verify succesful call
        setExteriorState((prevState) => ({
          ...prevState,
          responseData: { error: true },
        }));
      } else {
        setExteriorState((prevState) => ({
          ...prevState,
          responseData: { response: response.status },
        }));
        let link =
          "https://todd-thomas-painting.s3-us-west-2.amazonaws.com/ExteriorEstimates/" +
          outputFileName +
          ".pdf";
        window.open(link);
      }
    });
  }

  const handleNoteChange = (e) => {
    const { name, value } = e.target;

    let note = "";
    if (lineItems[0].description !== "") {
      note = "Additional Items Prices Seperately:\n";
      lineItems.forEach((lineItem) => {
        note = note + "\n" + lineItem.description + "\t\t " + lineItem.cost;
      });
      note = note + "\n\n";
    }
    if (exteriorState.noteTemp !== "" && name === "noteTemp") {
      note = note + "Notes:\n" + value;
    }
    if (name === "noteTemp") {
      setExteriorState((prevState) => ({
        ...prevState,
        note: note,
        noteTemp: value,
      }));
    } else {
      setExteriorState((prevState) => ({
        ...prevState,
        note: note,
      }));
    }
  };

  const handleSubmit = () => {
    createEstimate();
  };

  return (
    <Layout>
      <Styles>
        <h1>Exterior Estimate</h1>
        <br />
        <Form>
          <Form.Row>
            <Col xs="3">
              <Form.Label>Client Name: </Form.Label>
            </Col>
            <Col>
              <Form.Control
                name="clientName"
                type="text"
                placeholder="Enter Client Name"
                onChange={handleStateChange}
                value={exteriorState.clientName}
              />
            </Col>
          </Form.Row>
          <br />
          <Form.Row>
            <Col xs="3">
              <Form.Label>Client Address: </Form.Label>
            </Col>
            <Col>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  value: address,
                  onChange: handleAddressChange,
                  placeholder: 'Enter Client Address',
                }}
              />
            </Col>
          </Form.Row>
          <br />
          <Form.Row>
            <Col xs="3">
              <Form.Label>Client Email: </Form.Label>
            </Col>
            <Col>
              <Form.Control
                name="clientEmail"
                type="email"
                placeholder="Enter Client Email"
                onChange={handleStateChange}
              />
            </Col>
          </Form.Row>
          <br />
          <Form.Row>
            <Col xs="3">
              <Form.Label>Client Phone: </Form.Label>
            </Col>
            <Col>
              <Form.Control
                name="clientPhone"
                type="text"
                placeholder="Enter Client Phone"
                onChange={handleStateChange}
              />
            </Col>
          </Form.Row>
          <br />
          <hr />
          <Form.Row>
            <Col xs="3">
              <Form.Label>Home Sqft: </Form.Label>
            </Col>
            <Col xs="2">
              <Form.Control
                name="homeSqft"
                type="number"
                placeholder="Enter SQFT"
                required
                onChange={handleStateChange}
                value={homeSqft}
                disabled={sqftFetchState === "loading"}
              />
            </Col>
            <Col>
              {sqftFetchState === '' && (
                <Alert variant="secondary">
                  SQFT will be auto-grabbed from database after address is entered
                </Alert>
              )}
              {sqftFetchState === 'loading' && (
                <Alert variant="primary">
                  <Spinner animation="border" variant="primary" role="status" size="sm" />
                  Grabbing SQFT from Database...
                </Alert>
              )}
              {sqftFetchState === 'failure' && (
                <Alert variant="danger">
                  SQFT unavailable for this address. Please enter SQFT manually.
                </Alert>
              )}
              {sqftFetchState === 'success' && (
                <Alert variant="success">
                  Successfully Grabbed SQFT from Database!
                </Alert>
              )}
            </Col>
          </Form.Row>
          <br />
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Deluxe Package</Form.Label>
              <Form.Control
                name="deluxePackagePrice"
                type="text"
                value={pricing.deluxePackagePrice}
                onChange={(e) => setPricing({ ...pricing, deluxePackagePrice: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Ultimate Package</Form.Label>
              <Form.Control
                name="ultimatePackagePrice"
                type="text"
                value={pricing.ultimatePackagePrice}
                onChange={(e) => setPricing({ ...pricing, ultimatePackagePrice: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Maximum Package</Form.Label>
              <Form.Control
                name="maximumPackagePrice"
                type="text"
                value={pricing.maximumPackagePrice}
                onChange={(e) => setPricing({ ...pricing, maximumPackagePrice: e.target.value })}
              />
            </Form.Group>
          </Form.Row>
          <LineItems
            lineItems={lineItems}
            setLineItems={setLineItems}
            handleNoteChange={handleNoteChange}
          />
          <br />
          <Form.Row>
            <Col xs="8">
              <Form.Label>Additional Notes</Form.Label>
              <Form.Control
                name="noteTemp"
                value={exteriorState.noteTemp}
                as="textarea"
                rows={3}
                onChange={handleNoteChange}
              />
            </Col>
          </Form.Row>
          <br />
          <Button variant="primary" onClick={handleSubmit}>
            Download &darr;
          </Button>
        </Form>
      </Styles>
    </Layout>
  );
};
